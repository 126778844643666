export default function ({ routeName, slug }) {
  if (routeName === 'index') {
    // Home page
    return routeName
  } else if (slug) {
    // Dynamic page
    const slugRouteName = routeName.split('-slug')[0]

    // TODO: Remove this after app has it's own contact/campaign pages.
    // This would not work if, for example, /order-confirmation/_slug.vue would be added.
    // Then this path would be /order/confirmation/, which is incorrect.
    return `${slugRouteName.replace('-', '/')}/_slug`
  } else {
    // Overview page
    return `${routeName.replace('app-', 'app/')}/index`
  }
}
