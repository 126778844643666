const axios = require('axios')
const rateLimit = require('axios-rate-limit')
const token = process.env.DATO_API_TOKEN || process.env.datoApiToken

const datoInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Bearer ${token}`,
  },
})

const maxRetries = 5
let retries = 0

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

// We define an interceptor for "429: Too Many Requests"-error
// Dato responds with a header which state after how many seconds
// the server can be queried again. The query is called
// recursively after this time.
datoInstance.interceptors.response.use(
  response => response,
  async error => {
    if (error.config && error.response && error.response.status === 429) {
      const seconds = error.response.headers['x-ratelimit-reset']
      const milliSeconds = seconds * 1000

      console.warn(`DatoCMS: ${error.response.statusText}: Retrying after ${seconds} ${seconds > 1 ? 'seconds' : 'second'}`)

      await timeout(milliSeconds)

      return datoInstance.request(error.config)
    }

    if (retries < maxRetries) {
      retries += 1

      console.warn(`DatoCMS: ${error}: Retry attempt ${retries} of ${maxRetries}`)

      await timeout(5000)

      return datoInstance.request(error.config)
    }

    return Promise.reject(error)
  })

// Dato accepts 60 requests per 3 seconds, for this
// a rate limit is in place
const axiosWithRateLimit = rateLimit(datoInstance, {
  maxRequests: 60,
  perMilliseconds: 3000,
})

module.exports = (query, options) => {
  // For environments
  // https://graphql.datocms.com/environments/{ENVIRONMENT-NAME}
  // https://graphql.datocms.com/environments/{ENVIRONMENT-NAME}/preview
  const url = options && options.showDrafts ? 'https://graphql.datocms.com/preview' : 'https://graphql.datocms.com/'
  const variables = options && options.variables

  return axiosWithRateLimit
    .post(url, JSON.stringify({ query, variables }))
    .then(response => {
      if (response.status >= 200 && response.status < 300 && response.data && !response.data.errors) {
        return response.data.data // ¯\_(ツ)_/¯
      }

      if (response.data && response.data.errors) {
        return Promise.reject(response.data.errors)
      }

      return Promise.reject(response.data)
    })
}
