import getDumpedData from './get-dumped-data'
import fetchFromDato from './fetch-from-dato'

export default async function(context) {
  const data = process.env.cacheDato
    ? await getDumpedData(context)
    : await fetchFromDato(context)

  if (!data) {
    throw 'No data returned'
  }

  /*
   * Commit slugs of page for other locales to store,
   * to make them available when switching to another locale.
   */
  if (data.page?.localizedSlugs) {
    const i18nPageParams = data.page.localizedSlugs.reduce((slugMap, value) => {
      return {
        ...slugMap,
        [value.locale]: {
          slug: value.slug,
        },
      }
    }, {})
    context.store.dispatch('localization/setI18nPageParams', i18nPageParams)
  } else {
    context.store.dispatch('localization/setI18nPageParams', {})
  }

  return data
}
