import axios from 'axios'
import path from 'path'
import { DATA_CMS_DIR } from '~/lib/constants'

export default function (context) {
  const locales = context.app.i18n.locales.map(locale => `/${locale.code}`)

  // Test if route path contains one of the locales,
  // otherwise add the default locale as a fallback
  const routePath = new RegExp(locales.join('|')).test(context.route.path)
    ? context.route.path
    : `/${context.app.i18n.locale}${context.route.path}`
  const filePath = path
    .join(routePath, 'index.json')
    // TODO: Remove this after app has it's own contact/campaign pages.
    .replace('/app/', '/')

  if (process.client) {
    return axios.get(`/data/cms${filePath}`)
      .then(res => res.data)
  } else {
    const fullPath = path.join('src/client/', DATA_CMS_DIR, filePath)
    try {
      const data = JSON.parse(require('fs').readFileSync(fullPath))

      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
