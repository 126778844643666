import datoQuery from '../dato-query'
import getQueryPathFromRouteName from '../get-query-path-from-route-name'
import readQuery from '../read-query'

export default async function (context) {
  const slug = context.route.params.slug
  const routeName = context.route.name.match('.*(?=___)')?.[0] || context.route.name
  const variables = {
    locale: context.app.i18n.locale,
    slug,
  }

  const queryPath = getQueryPathFromRouteName({ routeName, slug })
  const query = readQuery(queryPath)
  const options = {
    showDrafts: process.env.datoShowDrafts,
    variables,
  }

  return datoQuery(query, options)
}
